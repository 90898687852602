import React from "react";
import { store } from "../../redux2/store";
import { CONNECT_WITH_SSO } from "../../redux2/types";

class KeyPad extends React.Component {
  //key pad code
  constructor(props) {
    super();
    this.state = {
      input: "",
      previousSetupID: 0,
    };
  }
  addDigit(digit) {
    // 20 is the max characters with the current CSS
    // literally adds string to string
    let string = this.state.input;
    if (string.length < 10) {
      string += digit;
    }
    this.setState({ input: string });
  }
  removeDigit() {
    // literally just slices last character in a string off
    let string = this.state.input.slice(0, this.state.input.length - 1);
    this.setState({ input: string });
  }
  generateString() {
    // displays the string, replaces all but the last character with "*"
    let string = "";
    for (let i = 0; i < this.state.input.length; i++) {
      if (i === this.state.input.length - 1) {
        string += this.state.input[i];
      } else {
        string += "*";
      }
    }
    return string;
  }
  submitString() {
    // call authentication api
    let params = {
      accesscode: this.state.input,
    };

    //TODO: Authenticate user login
    //Check if user inputted # is in the DB
    //Login is temporarily set to 1

    if (params.accesscode === "1") {
      console.log("good");
      store.dispatch({
        type: CONNECT_WITH_SSO,
        user: "test",
      });
    }
  }
  render() {
    return (
      <>
        <div id="KeypadLeft">
          {this.props.msg}
          <br></br>
          {this.generateString()}
          <br></br>
          {this.props.errmsg}
          {this.state.errmsg}
        </div>
        <div id="KeypadGrid">
          <div className="KeyPadBlock" onClick={() => this.addDigit(1)}>
            1
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(2)}>
            2
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(3)}>
            3
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(4)}>
            4
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(5)}>
            5
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(6)}>
            6
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(7)}>
            7
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(8)}>
            8
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(9)}>
            9
          </div>
          <div className="KeyPadBlock" onClick={() => this.removeDigit()}>
            X
          </div>
          <div className="KeyPadBlock" onClick={() => this.addDigit(0)}>
            0
          </div>
          <div className="KeyPadBlock" onClick={() => this.submitString()}>
            ✓
          </div>
        </div>
      </>
    );
  }
}

export default KeyPad;
