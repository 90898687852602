import React, { useState } from "react";
import "antd/dist/antd.css";

import { Descriptions, Badge } from "antd";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { MdArrowForwardIos } from 'react-icons/md';
import TextScroller from "./TextScroller";
import "slick-carousel/slick/slick-theme.css";
import { MdPrecisionManufacturing } from "react-icons/md";
import { RiTodoLine } from "react-icons/ri";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "./projectorsinglemo.css";


export default function Upcomingjobs({ selectedstationdata, mostatus }){
    const settings = {
   
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 0.8,
        autoplay: true,
        
        speed: 1000,
    
        cssEase: "linear"
      };
  return  <div className="upcomingdos">
    <div className="titlecoming">
      <h1>
        Upcoming Jobs <div className="borderbot"></div>{" "}
      </h1>
    </div>
 
    <div className="itemofupcoming_main_singlemo">   <Slider {...settings}> 
      {selectedstationdata.Upcomming?.map((item) => {
        return (
           
          <div className="itemofupcoming">
            <div className="up_title">
              {" "}
              <h1> MO - {item.order_no}</h1>
            </div>
            <div className="up_maindes">
              <h1>{item.routing_description}</h1>{" "}
            </div>{" "}
            <div className="partstotal1">
              <h1>{item.part_count}</h1>{" "}
              <div className="spana1"> /</div> <h1>{item.quantity}</h1>
            </div>
          </div>
        );
      })}
</Slider>         </div>
  </div>


}