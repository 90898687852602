import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import Projectorheropage from "./Projectorheropage";
import { Navbar, NavDropdown, Nav, Container } from "react-bootstrap";
import axios from "axios";
import Projectorsinglemo from "./Projectorsinglemo";

import "./projectorheropage.css";
import { Select } from "antd";
import imgh from "./haven_steel_log.png";
import Upcomingjobs from "./Upcomingjobs";
const { Option } = Select;

export default function Projector() {
  const [workstations, setworkstations] = useState([]);
  const [selectedworkstations, setselectedworkstations] = useState(
    "Select Work Station"
  );
  const[prevws,setprevws]=useState("");
  const[nextws, setnextws]=useState("");
  const [selectedstationdata, setselectedstationdata] = useState([]);
  function onChange(value) {
    console.log(`selected ${value}`);
    setselectedworkstations(value);
    onLeave();
  }
  function onSearch(val) {
    console.log("search:", val);
  }
  const[mostatus,setstatus]=useState("")
 useEffect(() => {
     console.log(mostatus);
 }, [selectedstationdata])
  const getworkstationdata = (params1) => {
    axios.get("https://routing-api.havensteel.alpacasys.com/monitor/getprojectordata", {
        params: {  workcenter: params1},
         })
         .then((res) => {
           setselectedstationdata(res.data);
           console.log("data", res.data)
           setprevws(res.data.prev_workstation);
           setnextws(res.data.nextworkstation);
//console.log(Math.round((res.data[0].part_count? res.data[0].part_count:0 / res.data[0].quantity) * 100))

         


if(Math.round( res.data.Running[0].part_count / res.data.Running[0].quantity* 100)<10){
            setstatus("Just Started")
        }
        
       else if(Math.round((250/ res.data.Running[0].quantity) * 100)<39){
        setstatus("Running")
    }
       else if(Math.round((250/ res.data.Running[0].quantity) * 100)==40){
            setstatus("HalfWay Completed")
        }
       else if(Math.round((30/ res.data.Running[0].quantity) * 100)>80){
            setstatus("Near Completion")
        }
           console.log(res.data);

         });
       
  };
  const params1=window.location.pathname.split("/")[2]
  console.log( params1.toUpperCase())
  useEffect(() => {
  
    console.log(params1);
     const interval = setInterval(() => {
    getworkstationdata(params1)
}, 5000);
return () => {
  clearInterval(interval);
};
  }, []);

  useEffect(() => {
  
    console.log(params1);
     
    getworkstationdata(params1)


  }, []);
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  useEffect(() => {
    onLeave();

    console.log(selectedworkstations);
  }, [selectedworkstations]);
  return (
    <div>
      <div className="nav_haven">
        <img
        style={{Width:"45vh", height:"7vh", marginLeft:"2vw"}}

          src={imgh}
        />
        <div
 style={{marginLeft:"12vw"}}
          onMouseEnter={onHover}
        ><h1 onMouseLeave={onLeave} style={{ color: "white",      fontSize: "6.2vh",
        fontFamily: `Roboto, sans-serif` }}>
        
    {decodeURI(params1).toUpperCase()}
     
      </h1>
        </div>
      </div>{selectedstationdata.Running &&selectedstationdata.Running.length>0?
     <Projectorsinglemo mostatus={mostatus} prevws={prevws} nextws={nextws}
      selectedstationdata={selectedstationdata}></Projectorsinglemo>:<> <>
      {" "}
      <div className="home">
        <div>
          

          <div className="Main_descn">
            <></>

            <div className="titleofcurrent">
              <h1>No Job is Running / Work Station doesnt exist</h1>
            </div>
            <h1> No Jobs</h1>
            <div className="partstotal">
              <div className="partscount">
                <h1>{0}</h1> <div className="spana"> /</div>{" "}
                <h1>{0}</h1>
              </div>
              <div className="statusname">
                <div style={{ textAlign: "center !important" }}>
                  {" "}
                  {`Status: None`}
                </div>
              </div>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="runningtext"
            >
              <p style={{ fontSize: "4vh" }}>No Routing Description</p>
            </div>
          </div>
        </div>
      </div>
    </></>

      }
{selectedstationdata.Upcomming &&selectedstationdata.Upcomming.length>1?


<Upcomingjobs mostatus={mostatus}
      selectedstationdata={selectedstationdata}></Upcomingjobs>:<></>
}
    
    </div>
  );
}
