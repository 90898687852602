import React from "react";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";

import KeyPad from "../../components/login/KeyPad.component";

const { Content } = Layout;
const LoginPage = withRouter(({ history }) => (
  <Layout style={{ minHeight: "100vh" }}>
    <Layout>
      <Layout className="site-layout">
        <Content style={{ margin: "0" }}>
          <div
            className="site-layout-background"
            style={{ padding: 0, height: "100vh" }}
          >
            <KeyPad></KeyPad>
          </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>
));

export default LoginPage;
