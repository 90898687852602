import React, { useState } from "react";
import "antd/dist/antd.css";

import { Descriptions, Badge } from "antd";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { MdArrowForwardIos } from 'react-icons/md';
import TextScroller from "./TextScroller";
import "slick-carousel/slick/slick-theme.css";
import { MdPrecisionManufacturing } from "react-icons/md";
import { RiTodoLine } from "react-icons/ri";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "./projectorsinglemo.css";
export default function Projectorsinglemo({ selectedstationdata, mostatus,nextws,prevws }) {
  function ucfirst(str) {
    var firstLetter = str.substr(0, 1);
    return firstLetter.toUpperCase() + str.substr(1);
  }
  const settings = {
   
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    
    autoplay: true,
    
    speed: 5000,

    cssEase: "linear"
  };
  const settings1 = {
    className: "center",
    infinite: true,
    slidesToShow: 2,
  
    autoplay: true,
    centerPadding: "60px",
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    
  };
  let selectedstationdata1 = [{}, {}, {}, {}, {}];
  return (
    <>
      {" "}
      <div className="home_singlemo">
        <div>
          {" "}
          <h1>
            In Progress
            <div className="borderbot"></div>
          </h1>
          <div className="main_flex_singlemo">
        
               
      {selectedstationdata.Running && selectedstationdata.Running.length<2?
    
    selectedstationdata.Running.map((item )=>
    
    
    
    {
        return<><div className="overall">  
        <div className="left"><h2>{prevws?prevws:"Just Started"} <MdArrowForwardIos/></h2> </div>
        
        <div className="Main_desc_singlemo1">
        <div className="titleofcurrent">
          <h1>MO - {item.order_no}</h1>
        </div>
        <h1 className="dec">{ucfirst(item.description)}</h1>
        <div className="partstotal">
          <div className="partscount">
            <h1>{item.part_count}</h1> <div className="spana"> /</div>{" "}
            <h1>{item.quantity}</h1>
          </div>
          <div className="statusname">
            <div style={{ textAlign: "center !important" }}>
              {" "}
        Completed  :
              <span className="com">
                {" "}
                {Math.round((item.part_count / item.quantity) * 100) +
                  `%`}{" "}
              </span>{" "}
            </div>
          </div>
        </div>
        <div className="runningtext">
          <p>{item.routing_description}</p>
        </div>
      </div>
       <div className="right_workstation"><h1><MdArrowForwardIos/>{nextws}  </h1> 
     </div>  </div></>
    }
    
    
    
    )
    
    
    

        : <Slider   className="current1" {...settings1}> {
            selectedstationdata.Running?.map((item) => {
              return (
                <div className="Main_desc_singlemo">
                  <div className="titleofcurrent">
                    <h1>MO - {item.order_no}</h1>
                  </div>
                  <h1>{ucfirst(item.description)}</h1>
                  <div className="partstotal">
                    <div className="partscount">
                      <h1>{item.part_count}</h1> <div className="spana"> /</div>{" "}
                      <h1>{item.quantity}</h1>
                    </div>
                    <div className="statusname">
                      <div style={{ textAlign: "center !important" }}>
                        {" "}
                        Completed :
                        <span className="com">
                          {" "}
                          {Math.round((item.part_count / item.quantity) * 100) +
                            `%`}{" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="runningtext">
                    <p>{item.routing_description}</p>
                  </div>
                </div>
              );
            })

        }            </Slider> 
           
    }
     
          </div> 
     
     
        </div>
      </div>

     
    </>
  );
}
