import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";

import MTUUserPage from "./pages/mtu/mtu.component";
import Projector from "./pages/mtu/Projector";
import MTUAdminPage from "./pages/mtu/admin.component";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { CONNECT_WITH_SSO } from "../src/redux2/types";
import { store } from "../src/redux2/store";
import axios from "axios";
import { connect } from "react-redux";
import LoginPage from "./pages/mtu/LoginPage.component";


const mapStateToProps = (state) => ({
  user: state.user,
});

function App({ history, user }) {
  var isUserThere = false;
  if (user) {
    isUserThere = true;
  } else {
    isUserThere = false;
  }
  console.log("yes");
  console.log(isUserThere);
  return (
  
      <div>
        <Switch><Route path="/projector" component={Projector}>
           </Route>
        
          <Route path="/projector/:workcenter" component={Projector}>
           
           </Route>
        </Switch>
      </div>
   
  );
}

export default connect(mapStateToProps)(App);
