import React from "react";
import { Layout } from "antd";


const { Content } = Layout;
const MTUPage = () => (
  <Layout style={{ minHeight: "100vh" }}>
    <Layout>
      <Layout className="site-layout">
        <Content style={{ margin: "0" }}>
          {/*
    <div className="site-layout-background" style={{ padding: 0, height: '100vh',cursor:'none'}}>
*/}
          <div
            className="site-layout-background"
            style={{ padding: 0, height: "100vh" }}
          >
          
          </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>
);

export default MTUPage;
